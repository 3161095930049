// $banner-media-height: 75vh;


.banner-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;

    @include breakpoint-up('md') {
        display: block;
    }
}






.banner {

    &.--minimal {
        @include spacing(pt-20);
        @include utils(text-center);
    }

    &.--not-event {
        background-color: color('grey-5');
        @include spacing(py-20);

        @include breakpoint-up('md+') {
            min-height: rem(320px);
            @include spacing(pt-24, pb-16);
            @include utils(flex, flex-column, flex-jc-center);
        }

        @include breakpoint-up('lg') {
            @include spacing(pb-24);
        }
    }

    &.--event {
        order: 2;
        background-color: color('black');

        @include breakpoint-up('md') {
            @include spacing(pt-2, pb-4);
            @include utils(flex, flex-column);
            // @include utils(flex-ai-center);
        }
    }

    &.--event-secondary {
        @include utils(flex, flex-column);
        @include spacing(pt-2, pb-4);
        background-color: color('white');
    }

    // Decorative strip of colour
    &.--strip {
        @include breakpoint-up('md') {
            &:after {
                content: "";
                @include utils(block, absolute);
                width: 30%;
                height: rem(70px);
                background-color: color('mint');
                background-color: var(--theme-color);
                opacity: 0.6;
                bottom: -4%;
                z-index: 1;
                left: 10%;
            }
        }

        @include breakpoint-up('lg') {
            &:after {
                width: 40%;
                height: rem(100px);
                bottom: -6%;
                left: 8%;
            }
        }
    }

    // Adds semi-transparent black over top of media
    &.--shade {
        color: color('white');

        a:not([class^='btn']),
        .link--stubby {
            color: color('white');
        }

        @include breakpoint-up('md') {
            @include utils(relative);

            & > :first-child {
                @include utils(relative);
                z-index: 2;
            }

            &::before {
                content: "";
                display: block;
                background: rgb(0, 0, 0);
                background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 93%, rgba(0,0,0,0.4) 100%);
                @include abs(0, 0, 0, 0, 1);
            }
        }
    }

    // For non-event banners with media, this tends to have small writing on in which requires darker shade
    &.--shade:not(.--event) {
        @include breakpoint-up('md') {
            &::before {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 12%, rgba(0, 0, 0, 0.25) 30%);
            }

            p {
                text-shadow: rem(1px) rem(0px) rem(3px) rgba(0,0,0,0.4);
            }
        }
    }

    &.--shade.--home {
            @include breakpoint-up('md') {
            &::before {
                background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0.2) 93%, rgba(0,0,0,0.4) 100%);
            }
        }
    }


    // Used when media is placed behind banner content (image / video)
    &.--media {
        @include utils(flex, flex-column, flex-jc-end);
        @include spacing(py-0);

        @include breakpoint-up('md+') {
            min-height: rem(550px);
        }

        $banner__main--overlap: 10rem;

        .banner-content {
            @include utils(flex-order-2, w-100);
            @include spacing(pb-4);
            background: rgb(0,0,0);
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1) $banner__main--overlap, rgba(0, 0, 0, 1));
            padding-top: ($banner__main--overlap - 2rem); // offset it slightly so text overlaps a bit.. niiiiice.
            margin-top: -#{$banner__main--overlap};

            @include breakpoint-up('md+') {
                @include spacing(mt-0, mb-8, pt-0, pb-0);
                background: none;
            }
        }

        .banner-media {
            // overflow fix for old Chrome where the media would flow
            overflow-y: hidden;

            @include utils(flex-order-1);
            max-height: 50vh;

            img,
            video {
                object-fit: cover;
                @include utils(w-100, h-100);
                // @include spacing(pb-24);
            }

            @include breakpoint-up('md+') {
                @include utils(absolute, h-100, w-100, top-0);
                max-height: none;

                img,
                video {
                    @include spacing(pb-0);
                }
            }
        }
    }


    // Extra rules when there is media on top of an event banner (they're tricky)
    &.--media.--event {
        @include breakpoint-up('md') {
            height: 75vh;
        }

        & ~ .banner-media {
            // overflow fix for old Chrome where the media would flow
            overflow-y: hidden;

            @include breakpoint-up('md') {
                height: auto;
                @include utils(absolute, h-100, w-100, top-0);
            }

            img,
            video {
                object-fit: cover;
                @include utils(w-100, h-100);
            }
        }
    }

    &.--media.--home {
        .super {
            line-height: 1;
        }

        @include utils(flex-jc-end);

        @include breakpoint-up('md') {
            .event-banner__main {
                @include spacing(pb-12);
            }
        }

        @include breakpoint-up('md+') {
            .event-banner__main {
                @include spacing(pb-16);
            }
        }

        @include breakpoint-up('lg') {
            @include utils(flex-jc-center);

            .event-banner__main {
                @include spacing(pb-0);
            }
        }
    }

    &.--media.--home,
    &.--media.--event {

        & ~ .banner-media {
            @include breakpoint-down('sm+') {
                height: 50vh;
            }
        }
    }

}



    $event-banner__main--overlap: 10rem;

    .event-banner__main {
        background: rgb(0,0,0);
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1) $event-banner__main--overlap, rgba(0, 0, 0, 1));
        @include spacing(pb-4);
        padding-top: ($event-banner__main--overlap - 2rem); // offset it slightly so text overlaps a bit.. niiiiice.
        margin-top: -#{$event-banner__main--overlap};
        z-index: 1;

        @include breakpoint-up('md') {
            background: none;
            @include spacing(pb-0);
        }
    }

    .event-banner__actions {
        @include utils(absolute, top-0);
        // right: rem($container-spacing-sm);

        @include breakpoint-up('md') {
            @include utils(static);
        }
    }







.vertical-text {
    writing-mode: vertical-rl;
    @include utils(absolute, left-0, nowrap);
    @include spacing(ml-0);
}


// @todo: This contains fixed values
$offset-shade-value: 5%;
$offset-shade-width: ($offset-shade-value * 2) + 100%;

.offset-shade {
    @include utils(relative);

    &::after {
        content: "";
        @include utils(block, absolute, bg-grey-5, h-100);
        @include spacing(ml-0);
        width: calc(#{$offset-shade-width} - 48px);
        z-index: -1;
        top: 20%;
        margin-left: -#{$offset-shade-value};
    }
}