@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loader {
    text-align: center;

    &::before {
        content: '';
        display: inline-block;
        border: 3px solid rgba(color('grey-50'), 0.2);
        border-top-color: color('grey-50');
        border-radius: 50%;
        width: 3em;
        height: 3em;
        animation: spin 1s linear infinite;
    }
}

.theme-dark .loader::before {
    border: 3px solid rgba(color('white'), 0.2);
    border-top-color: color('white');
}



.loading-pulse {
    animation: opacity-pulse 2s linear infinite;
}




.spinner {
    width: rem(64px);
    height: rem(64px);
    border: rem(8px) solid;

    position: relative;
    color: color('grey-20');
    display: inline-block;

    border-radius: 50%;
    border-right-color: color('mint');
    border-right-color: var(--theme-color);
    animation: rotate 1s linear infinite;



    @include breakpoint-up('md') {
        height: rem(150px);
        width: rem(150px);
        border-width: rem(15px);
    }

    @include breakpoint-up('lg') {
        height: rem(200px);
        width: rem(200px);
        border-width: rem(20px);
    }
}


@keyframes rotate {
    0% {
        transform: rotate(0); }
    100% {
        transform: rotate(360deg); }
}