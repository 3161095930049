@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes slideInUp {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes slideOutDown {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

@keyframes slideOut {
    0% { transform: translateY(0); }
    100% { transform: translateX(-100%); }
}

@keyframes slideIn {
    0% { transform: translateX(-100%); }
    100% { transform: translateY(0); }
}

@keyframes opacity-pulse {
    0% { opacity: 1; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
}
