$font-path: '/static/fonts/';


@font-face {
    font-family: 'TTNorms';
    src: url('#{$font-path}/TTNorms-Regular.eot');
    src: url('#{$font-path}/TTNorms-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/TTNorms-Regular.woff2') format('woff2'),
        url('#{$font-path}/TTNorms-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'TTNorms';
    src: url('#{$font-path}/TTNorms-Regular-Italic.eot');
    src: url('#{$font-path}/TTNorms-Regular-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/TTNorms-Regular-Italic.woff2') format('woff2'),
        url('#{$font-path}/TTNorms-Regular-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'TTNorms';
    src: url('#{$font-path}/TTNorms-Medium.eot');
    src: url('#{$font-path}/TTNorms-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/TTNorms-Medium.woff2') format('woff2'),
        url('#{$font-path}/TTNorms-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'TTNorms';
    src: url('#{$font-path}/TTNorms-Medium-Italic.eot');
    src: url('#{$font-path}/TTNorms-Medium-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/TTNorms-Medium-Italic.woff2') format('woff2'),
        url('#{$font-path}/TTNorms-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'TTNorms';
    src: url('#{$font-path}/TTNorms-Bold.eot');
    src: url('#{$font-path}/TTNorms-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/TTNorms-Bold.woff2') format('woff2'),
        url('#{$font-path}/TTNorms-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'TTNorms';
    src: url('#{$font-path}/TTNorms-Bold-Italic.eot');
    src: url('#{$font-path}/TTNorms-Bold-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/TTNorms-Bold-Italic.woff2') format('woff2'),
        url('#{$font-path}/TTNorms-Bold-Italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Rational';
    src: url('#{$font-path}/Rational-TW-Display-Semibold.eot');
    src: url('#{$font-path}/Rational-TW-Display-Semibold.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/Rational-TW-Display-Semibold.woff2') format('woff2'),
        url('#{$font-path}/Rational-TW-Display-Semibold.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
