ul,
.default-margins .list-zero {
    list-style: none;
    padding: 0;
    margin: 0;
}

ol,
ul.bullet-list {
    @include spacing(pl-8);
}

ul.bullet-list {
    list-style: initial;
}


dl {
    font-weight: bold;
}

.list-reset {
    @include list-reset;
}

// MIKE
.default-margins {
    ul {
        list-style: initial;
        @include spacing(pl-8, mb-12);

        li {
            margin-bottom: 1.5em;
        }
    }
}
