@import '~@octavenz/reoako/dist/reoako.scss';

.theme--dark .reoako-trigger {
    box-shadow: inset 0 0 0 0.1em #e5e5e5;
    color: #e5e5e5;

    &:hover {
        background: #3A3A3A;
    }

    &:focus,
    &:active {
        background: #e5e5e5;
        color: #3A3A3A;
    }
}