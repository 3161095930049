.popover {
    @include utils(shadow-1, rounded);
    @include spacing(px-6, py-4);
    @include transition(box-shadow);
    transform: translateX(-50%);
    margin-left: 50%;
    max-width: rem(500px);

    &:hover {
        @include utils(shadow-2, cursor-pointer);
    }
}
