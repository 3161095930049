$line-height-default: 1.5;

$font-path: '/static/fonts/';

$font-family-base: 'TTNorms', sans-serif;
$font-family-headings: 'TTNorms', sans-serif;
$font-family-alt: 'Rational', monospace;
$line-height-default: 1.5;

$font-weight-hairline: 100;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$font-weight-base: $font-weight-normal;
$font-weight-headings: $font-weight-bold;

$font-styles: (
  default:(
    family: $font-family-base,
    weight: $font-weight-normal,
    line-height: 1.78,
    sizes: (
      default: 16px,
      md: 18px,
      // lg: 18px,
    )
  ),

  super: (
    line-height: 0.9,
    sizes: (
      default: 36px,
      md: 72px,
      lg: 96px,
    )
  ),

  xlarge: (
    sizes: (
      default: 20px,
      md: 22px,
      lg: 24px,
    )
  ),

  large: (
    sizes: (
      default: 18px,
      md: 19px,
      lg: 20px,
    )
  ),

  small: (
    line-height: 1.68,
    sizes: (
      default: 14px,
      md: 16px,
      // lg: 16px,
    )
  ),

  tiny: (
    line-height: 1.3,
    sizes: (
      default: 12px,
      md: 14px,
      lg: 14px,
    )
  ),

  h1: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    line-height: 1,
    utils: (tracking-n2),
    sizes: (
      default: 40px,
      md: 56px,
      lg: 72px,
    )
  ),

  h2: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    line-height: 1,
    utils: (tracking-n1),
    sizes: (
      default: 32px,
      md: 40px,
      lg: 48px,
    )
  ),

  h3: (
    family: $font-family-headings,
    weight: $font-weight-medium,
    line-height: 1.25,
    utils: (tracking-n1),
    sizes: (
      default: 21px,
      md: 28px,
      lg: 32px,
    )
  ),

  h4: (
    family: $font-family-headings,
    weight: $font-weight-medium,
    line-height: 1.5,
    sizes: (
      default: 18px,
      md: 21px
    )
  ),

  h5: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    line-height: 1.5,
    sizes: (
      default: 16px,
      md: 18px
    )
  ),

  h6: (
    family: $font-family-headings,
    weight: $font-weight-medium,
    line-height: 1.5,
    utils: (uppercase, tracking-4),
    sizes: (
      default: 13px,
      md: 14px,
      lg: 16px
    )
  ),

  h6-small: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    line-height: 1.5,
    utils: (uppercase, tracking-3),
    sizes: (
      default: 10px,
      md: 12px
    )
  ),
);

$tracking-n2: -0.04em;
$tracking-n1: -0.025em;
$tracking-1: 0.025;
$tracking-2: 0.05em;
$tracking-3: 0.2em;
$tracking-4: 0.3em;

$colors: (
    "black": #000000,
    "off-black": #151617,
    "white": #FFFFFF,
    "theme-color": var(--theme-color),
    "theme-color-dark": var(--theme-color-dark),

    // Greys
    "grey":    #6E6E6E,
    "grey-90": #2C2D2E,
    "grey-80": #444545,
    "grey-70": #626363,
    "grey-60": #7C7C7D,
    "grey-50": #969696,
    "grey-40": #AFB0B0,
    "grey-30": #C9C9C9,
    "grey-20": #D0D0D1,
    "grey-10": #E7E7E7,
    "grey-5":  #F3F3F3,

    // "black-muted": rgba(#0A1A40, 0.8),
    // 'white-opaque': rgba(#FFF, 0.5),

    // Reds
    "red": #C22436,
    "red-5": #F7D4D9,
    "dark-pink": #C71585,

    // Theme colours
    "mint": #CADFC0,
    "mint-dark": #A9CA99,
    "coral": #FFCFDF,
    "coral-dark": #FFCFDF,
    "lilac": #ADA7D2,
    "lilac-dark": #857CB7,
    "peach": #F1BA9D,
    "peach-dark": #E1A180,
    "bone": #DBD7CC,
    "bone-dark": #BEB9AB,
    "teal": #CEEDED,
    "teal-dark": #A6DDDD,
    "lemon": #FBEDC7,
    "lemon-dark": #F3DA97,

    // The Culture Club
    "culture-blue": #0D89AB,
    "culture-blue-dark": #055C73,

    // Blues
    "gradient-blue": #070C5E,
    "dark-blue": #003478,
    "bright-blue": #0F4DBC,
    "notification-blue": #F6FAFF,
    "notification-blue-border": #D4D6EE,

    // Greens

    "light-green": #C1FC99,
    "light-green-tint": #CAF1B1,
    "dark-green": #00AE65,
    "notification-green": #F5FCF9,
    "notification-green-border": #B7F1D9,


    // Yellows
    "yellow": #FFC312,
    "orange": #EE5A24,

    "transparent": rgba(0, 0, 0, 0),
);

$flex: (
    flex-row:            (flex-direction: row),
    flex-column:         (flex-direction: column),
    flex-row-reverse:    (flex-direction: row-reverse),
    flex-column-reverse: (flex-direction: column-reverse),

    flex-wrap:         (flex-wrap: wrap),
    flex-nowrap:       (flex-wrap: nowrap),
    flex-wrap-reverse: (flex-wrap: wrap-reverse),

    flex-fill:         (flex: 1 1 auto),
    flex-grow-0:       (flex-grow: 0),
    flex-grow-1:       (flex-grow: 1),
    flex-shrink-0:     (flex-shrink: 0),
    flex-shrink-1:     (flex-shrink: 1),

    flex-jc-start:   (justify-content: flex-start),
    flex-jc-end:     (justify-content: flex-end),
    flex-jc-center:  (justify-content: center),
    flex-jc-between: (justify-content: space-between),
    flex-jc-around:  (justify-content: space-around),

    flex-ai-start:    (align-items: flex-start),
    flex-ai-end:      (align-items: flex-end),
    flex-ai-center:   (align-items: center),
    flex-ai-baseline: (align-items: baseline),
    flex-ai-stretch:  (align-items: stretch),

    flex-ac-start:   (align-content: flex-start),
    flex-ac-end:     (align-content: flex-end),
    flex-ac-center:  (align-content: center),
    flex-ac-between: (align-content: space-between),
    flex-ac-around:  (align-content: space-around),
    flex-ac-stretch: (align-content: stretch),

    flex-as-auto:     (align-self: auto),
    flex-as-start:    (align-self: flex-start),
    flex-as-end:      (align-self: flex-end),
    flex-as-center:   (align-self: center),
    flex-as-baseline: (align-self: baseline),
    flex-as-stretch:  (align-self: stretch),

    flex-order-first: (order: -9999),
    flex-order-last: (order: 9999),
    flex-order-none: (order: 0),
    flex-order-1: (order: 1),
    flex-order-2: (order: 2),
    flex-order-3: (order: 3),
    flex-order-4: (order: 4),
    flex-order-5: (order: 5),
    flex-order-6: (order: 6),
    flex-order-7: (order: 7),
    flex-order-8: (order: 8),
    flex-order-9: (order: 9),
    flex-order-10: (order: 10),
    flex-order-11: (order: 11),
    flex-order-12: (order: 12),
);
