// Links
.btn--link,
a {
    color: $link-color;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        // color: $link-hover-color;
        text-decoration: none;
    }

    &.link--reverse,
    .reverse-links & {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}


// MIKE
.link--no-hover { // Hover state visualised in other ways
    &:hover {
        text-decoration: none;
    }
}

.link--stubby {
    @include render-font-style(h6-small);
    @include utils(inline-block);
    @include spacing(py-2, px-2);
    color: color('black');
    border-color: color('black'); // used by .--reverse class

    @include breakpoint-up(md) {
        @include spacing(px-3);
    }

    span {
        @include utils(inline-block, border2-bottom);
        @include spacing(pb-1);
        // border-color: inherited from general style rule
    }

    &:hover {
        color: color('black');
        cursor: pointer;
        text-decoration: none;

        span {
            border-color: color('black');
        }
    }

    .color-white &,
    &.color-white,
    .--shade & {
        color: color('white');
        border-color: color('white'); // used by .--reverse class

        span {
            border-color: color('white');
        }

        &:hover {
            span {
                border-color: transparent;
            }
        }
    }
}

.color-white-link {
    a {
        color: color('white');
    }
}

// For containing multiple .link--stubby so they keep their spacing/padding, but line-up when breaking to to lines / align to grid, etc
.link--stubby-lockup {
    @include spacing(mx-n2);

    @include breakpoint-up('md') {
        @include spacing(mx-n3);
    }
}

.link--alt {
    // @include utils(inline-block, color-black, no-underline, bg-grey-5);
    @include utils(inline-block, color-black, no-underline, border2-bottom, border-transparent);

    &:hover {
        // @include utils(no-underline, bg-grey-20, color-black);
        // background-color: var(--theme-color);

        border-color: color('mint');
        border-color: var(--theme-color);
    }
}


.sponsor {
    &:hover {
        @include utils(relative, opacity-50);
        top: rem(-2px);
    }
}

