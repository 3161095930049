@function make-grid-class-map($columns: 12) {
    $class-map: (
        col: (
            flex-basis: 0,
            flex-grow: 1,
            max-width: 100%,
        ),
        c-auto: (
            flex: 0 0 auto,
            width: auto,
            max-width: 100%,
        )
    );

    @for $i from 1 through $columns {
        $class-map: map-merge($class-map, (
            c#{$i}: (
                flex: 0 0 percentage($i / $columns),
                max-width: percentage($i / $columns),
            )));
    }

    @for $i from 0 through ($columns - 1) {
        $num: $i / $columns;
        $class-map: map-merge($class-map, (
            o#{$i}: (margin-left: percentage($num))
        ));
    }

    @return $class-map;
}

$grid-column-classes: make-grid-class-map($columns: 12);

@mixin grid {
    display: flex;
    flex-wrap: wrap;

    > * {
        position: relative;
        width: 100%;
    }
}

@mixin gutters($gutter-width) {
    margin-right: -$gutter-width / 2;
    margin-left: -$gutter-width / 2;

    > * {
        padding-right: $gutter-width / 2;
        padding-left: $gutter-width / 2;
    }
}
