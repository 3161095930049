// Utils values are defined in config/utils


/* * * * * * * * * * * * * * * * * * * * * * * *
* Border Class output
*   set default border rules for all elements
*   this allows us to specify only e.g `.border` or `.border-top` to an element
*   and sensible defaults are applied
* * * * * * * * * * * * * * * * * * * * * * */
* {
    border-width: 0;
    border-style: solid;
    border-color: color('grey-30');
}
@include make-responsive-util-classes($borders);


/* * * * * * * * * * * * * * * * * * * * * * * *
* All other util class outputs
* * * * * * * * * * * * * * * * * * * * * * */

// border-radius
@include make-responsive-util-classes($border-radius);

// box-shadow
@include make-responsive-util-classes($shadows);

// dimensions: width/height/min/max utils
@include make-responsive-util-classes($dimensions);

// display
@include make-responsive-util-classes($display);
@include make-print-util-classes($display);

// flex
@include make-responsive-util-classes($flex);

// float
@include make-responsive-util-classes($float);

// mouse utils
@include make-responsive-util-classes($mouse);

// opacity
@include make-responsive-util-classes($opacity);

// position
@include make-responsive-util-classes($position);
@include make-responsive-util-classes($z-index);
@include make-responsive-util-classes($placement);

// spacing
@include make-responsive-util-classes($spacing);
@include make-print-util-classes($spacing);

// lists
@include make-responsive-util-classes($lists);

// text utils
@include make-responsive-util-classes($text);

// colors
@include make-util-classes($color-class-map);



/* * * * * * * * * * * * * * * * * * * * * * * *
* Miscellaneous - a few useful helper classes
* * * * * * * * * * * * * * * * * * * * * * */

.stretched-link {
    &::after {
        @include utils('absolute', 'inset-0', 'z-1');
        content: '';
        pointer-events: auto;
        background-color: transparent;
    }
}


.debug * {
    background: rgba(#F9CA24, 0.05);
    box-shadow: 0 0 0 1px #F9CA24;

    &::after,
    &::before {
        background: rgba(#FF7979, 0.05);
        box-shadow: 0 0 0 1px #FF7979;
    }
}
