.content_stream_block { // generic block class
    & + & {
        @include spacing(mt-12);

        @include breakpoint-up('md') {
            @include spacing(mt-24);
        }
    }
}



.content_stream_block--two_column_block {
    h6 {
        @include render-font-style(h6-small);
    }
}


// Use this class to contain all Streamfield content under the banner
.streamfield-content {
    @include spacing(mb-16);

    @include breakpoint-up('md') {
        @include spacing(mb-32);
    }

    // First child rule
    & > *:first-child {
        @include spacing(mt-16);

        @include breakpoint-up('md') {
            @include spacing(mt-32);
        }
    }

    // Exceptions to first-child rule
    .block--event-collection {
        &:first-child {
            @include spacing(mt-0);
        }
    }

    .content_stream_block--intro_text_block {
        &:first-child {
            @include spacing(mt-8);

            @include breakpoint-up('md') {
                @include spacing(mt-16);
            }
        }
    }
}

.culture-club {
    .content_stream_block--sponsor_group_block {
        h6 {
            @include utils(text-center);
        }

        .sponsor-group > div {
            @include utils(flex-jc-center);
        }

        .sponsor {
            flex: 1 1 50%;
            max-width: 50%;

            @include breakpoint-up('md') {
                flex-basis: 25%;
                @include spacing(mt-8);
            }
        }
    }
}