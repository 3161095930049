.breadcrumbs {
    a {
        @include utils(nowrap);
        @include spacing(pr-4);
        @include render-font-style(h6-small);
    }

    a + a {
        @include spacing(pl-6);
        @include utils(relative);

        &:before {
            content: "▸";
            top: rem(-4px);
            @include utils(absolute, left-0);
        }
    }
}