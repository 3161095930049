hr {
    color: $border-color;
    background-color: currentColor;
    border: 0;

    // @include breakpoint-up(md) {
        @include spacing(my-6);
    // }

    @include breakpoint-up(lg) {
        @include spacing(my-8);
    }
}

hr:not([size]) {
    height: 1px;
}

hr + * {
    @include spacing(mt-0);
}
