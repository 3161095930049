
/* * * * * * * * * * * * * * * * * * * * * * * *
* Grid Class output
* * * * * * * * * * * * * * * * * * * * * * */

$grid-classname: grid !default;

.#{$grid-classname} { @include grid; }

.#{$grid-classname} .gutters,
.#{$grid-classname}--gutters {
    @include gutters(30px);
}

.#{$grid-classname} .gutters-sm,
.#{$grid-classname}--gutters-sm {
    @include gutters(12px);
}

@include make-responsive-util-classes($grid-column-classes, $important: false);
