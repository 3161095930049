%notice {
    @include spacing(px-4, py-5);

    color: $body-color;
    background-color: color('grey-5');
    border: 1px solid color('grey-30');
    border-top-width: 3px;

    .default-margins & {
        @include spacing(mt-4);

        :first-child {
            margin-top: 0;
        }
    }
}

.notice {
    @extend %notice;
}

.notice--blue {
    @extend %notice;
    background: color('notification-blue');
    border-color: color('notification-blue-border');
}


.notice--sm {
    @extend %notice;
    @include spacing(px-2, py-2);
}
