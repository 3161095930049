// html button reset
// -------------------------------
button,
[type=button],
[type=submit] {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-align: inherit;
    cursor: pointer;
}


// Base styled button styles
.btn {
    @include spacing(py-2, px-5);
    @include transition((border-color, color, background-color));
    @include utils(antialiased, uppercase, tracking-2);
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    // border: none;

    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    white-space: nowrap;
    text-decoration: none !important;
    // border-width: 1px;
    // border-style: solid;
    cursor: pointer;

    // & + &:not(.btn--full) {
    //     @include spacing(ml-2, mt-2);
    // }

    &.disabled,
    &:disabled {
        box-shadow: none;
        pointer-events: none;
        border: none;
        background-color: #CCCCCC;
        color: #A3A3A3;

        i {
            opacity: 0.3;
        }
    }

    &:hover {
        @include transition((none)); // creates a snappier hover effect
    }
}

// Button Variants (Sizes, etc)
// -------------------------------
.btn--full {
    width: 100%;

    @include default-margins() {
        + * {
            @include spacing(mt-4);
        }
    }
}

.btn--large {
    @include spacing(py-2, px-3);
    @include utils(uppercase, tracking-2, font-bold);
    font-size: rem(12px);

    @include breakpoint-up('sm') {
        font-size: rem(14px);
    }

    @include breakpoint-up('md') {
        @include spacing(py-4, px-6);
        font-size: rem(18px);
    }
}

    .btn--buy-tix {
        font-size: rem(16px);
        @include spacing(py-3, px-4);

        @include breakpoint-up('sm') {
            font-size: rem(18px);
        }

        @include breakpoint-up('md') {
            font-size: rem(18px);
        }
    }


.btn--medium {
    @include spacing(py-3, px-3);
    @include utils(uppercase, tracking-2, font-bold);
    font-size: rem(12px);

    @include breakpoint-up('md') {
        @include spacing(py-4, px-4);
        font-size: rem(14px);
    }
}

// Make a button look and behave like a link
// .btn--link { /* See base/links  */}


// Button Themes (Colours)
// -------------------------------

// default theme
.btn--default {
    color: color('white');
    background-color: color('black');

    &:hover {
        color: color('white');
        background-color: color('grey-70');
    }
}

.btn--reverse {
    color: color('black');
    background-color: color('white');

    &:hover {
        color: color('black');
        background-color: color('grey-20');
    }
}

.btn--secondary {
    color: color('black');
    background-color: color('mint');
    background-color: var(--theme-color);

    &:hover {
        color: color('black');
        background-color: color('mint-dark');
        background-color: var(--theme-color-dark);
    }
}

    .btn--secondary-hollow {
        color: color('white');
        color: var(--theme-color);
        border-color: color('mint');
        border-color: var(--theme-color);
        background-color: rgba(0, 0, 0, 0.2);
        border-width: rem(2px);
        border-style: solid;

        &:hover {
            color: color('mint-dark');
            color: var(--theme-color-dark);
            border-color: color('mint-dark');
            border-color: var(--theme-color-dark);
        }

        @include breakpoint-up('md') {
            border-width: rem(4px);
        }
    }

.btn--white {
    background-color: color('white');
    border-color: color('white');
    color: color('black');

    &:hover {
        background-color: color('grey-40');
        border-color: color('grey-40');
        color: color('black');
    }
}

.btn--culture {
    background-color: color('culture-blue');
    color: color('white');

    &:hover {
        background-color: color('culture-blue-dark');
        color: color('white');
    }
}

.btn--hollow {
    background-color: transparent;
    border-color: color('black');
    color: color('black');
}

.btn--white-hollow {
    background-color: transparent;
    border-color: color('white');
    color: color('white');
}




// Buy ticket button lockup
.btn-lockup {
    @include spacing(mt-n1, mb-n1, ml-n1, mr-n1);
    @include utils(flex, flex-wrap, flex-ai-stretch); //flex-jc-center,

    .btn {
        @include spacing(m1);
        @include utils(inline-flex, flex-ai-center, flex-jc-center);
    }

    .btn + .btn {
        @include spacing(ml-1);
    }

    // TODO: Is this the best way to select this?
    [data-shortlist-toggle] {
        @include spacing(m1);
    }

    .btn--shortlist {
        @include spacing(m0);
        @include utils(h-100);
        border: solid 2px color('black');
    }

    &.--buy-tix {
        .btn--buy-tix {
            flex: 1 1 rem(160px);
        }

        [data-shortlist-toggle] {
            flex: 1 1 rem(120px);
        }

        .deal-alert {
            flex-basis: rem(300px);
        }
    }
}

