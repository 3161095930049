$table-bg-color: color('grey-10');
$table-border-color: color('grey-20');
$table-text-color: color('black');;

table {
    width: 100%;
    color: $table-text-color;

    th,
    td {
        @include spacing(p2);
        vertical-align: top;
    }

    thead th {
        vertical-align: bottom;
        background-color: $table-bg-color;
    }
}

th {
    font-weight: $font-weight-medium;
}

.table--lg {
    th,
    td {
        @include spacing(p3);
    }
}


.table--border-rows {
    th,
    td {
        border-top: 1px solid $table-border-color;
    }

    thead th {
        border-bottom: 1px solid $table-border-color;
    }

    tbody + tbody {
        border-top: 1px solid $table-border-color;
    }
}

// Border versions
// Add or remove borders all around the table and between all the columns.
.table--bordered {
    border: 1px solid $table-border-color;

    th,
    td {
        border: 1px solid $table-border-color;
    }

    thead {
        th,
        td {
            border-bottom-width: 1px;
        }
    }
}

.table--borderless {
    th,
    td,
    thead th,
    tbody + tbody {
        border: 0;
    }

    tr {
        :first-child {
            @include spacing(pl-0);
        }

        :last-child {
            @include spacing(pr-0);
        }
    }
}

// Zebra-striping
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table--striped {
    tbody tr:nth-of-type(odd) {
        background-color: $table-bg-color;
    }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table--hover {
    tbody tr {
        transition: background-color 0.3s $easing-standard;

        &:hover {
            background-color: color('black');
            color: color('white');
        }
    }
}

.responsive-table {
    @include nice-scrollbars;
    position: relative;
    width: calc(100vw);
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;

    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 3px;

    th {
        white-space: nowrap;
    }
}

.responsive-table__inner {
    //padding-right: 20px;
    //position: relative;
}



