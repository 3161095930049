.review,
.quote {
    blockquote {
        div,
        p,
        cite {
            @include utils(inline-block);
        }
    }

    .review__body {
        @include utils(font-bold);
        @include spacing(pr-2);
    }

    &.--stars {
        cite {
            @include utils(relative); // Align to stars
            top: rem(2px);
        }
    }

    a {
        &:hover { // <cite> can be placed within <a>
            cite {
                @include utils(underline);
            }
        }
    }

    cite {
        @include utils(not-italic); //, block
        @include render-font-style(h6);
        // @include spacing(mt-4);
    }

    & + & {
        @include spacing(mt-8);
    }

    &.--stars + &.--stars {
        @include spacing(mt-0);
    }
}

.primary_review .review,
.quote {
    p {
        font-size: rem(20px);

        @include breakpoint-up('md') {
            font-size: rem(24px);
        }
    }
}