.theme-color {
    color: color('mint'); // fallback
    color: var(--theme-color);
}

.theme-color-dark {
    color: color('mint-dark'); // fallback
    color: var(--theme-color-dark);
}

.theme-bg-color {
    background-color: color('mint'); // fallback
    background-color: var(--theme-color);
}

// TODO: Not working. And it's tricky and possibly impossible.
.theme-bg-color-tint {
    @include background-opacity(color('mint'), 0.3);
    @include background-opacity(var(--theme-color), 0.3);
}


.theme-dark {
    @include utils(antialiased);
    color: color('white');

    a {
        color: color('white');
    }
}
