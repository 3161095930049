img {
    max-width: 100%;
    height: auto;

    &.fill {
        width: 100%;
    }

    &.fill-portrait {
        max-height: 90vh;
        width: auto;
    }
}

// MIKE
// Aspect ratios
.aspect-ratio {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    & > :first-child {
        @include abs(0, 0, 0, 0, 1);
    }

    &::before {
        content: "";
        display: block;
    }
}

.aspect-ratio--16by9::before {
    padding-top: 56.25%;
}

.aspect-ratio-square::before {
    padding-top: 100%;
}

.aspect-ratio--portrait::before {
    padding-top: 139.5%;
}

.aspect-ratio--tile::before {
    padding-top: 114.667%;
}

.responsive-object,
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    background-color: rgba(255,255,255,0.1);
    clear: both;
}

.responsive-object,
.video-wrapper {
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}