.loading-space {
    width: 100vw;
    height: calc(100vh - 84px); // TODO: Magic number! This is height of the header on desktop
    background-color: color('grey-10');
}



.filter-heading {
    @include render-font-style(h2);
}

.filter-tag {
    @include utils(bg-black, color-white, inline-flex, flex-ai-center, no-underline);
    @include spacing(px-3, pt-3, pb-2, mb-1);

    svg {
        @include utils(color-white);
    }

    &:hover,
    &:focus {
        @include utils(no-underline, bg-grey-80, color-white);
    }

    // Used for default heading, keeps spacing all the same
    &.--faux {
        &,
        &:hover,
        &:focus {
            @include utils(bg-white, color-black);
        }
    }

    @include breakpoint-up('md') {
        @include spacing(px-4, pt-4, pb-3);
    }

    .filter-wrapper--subset & {
        @include utils(font-medium);
    }
}


.filter-group { // <div>
    @include breakpoint-down('sm+') {
        @include spacing(mt-n2, pt-4, pb-8);
        background-color: color('grey-5');
        margin-left: rem(-$container-spacing-sm);
        margin-right: rem(-$container-spacing-sm);
        padding-left: rem($container-spacing-sm);
        padding-right: rem($container-spacing-sm);

        // @include spacing(mx-2, mt-6, px-6, py-8);
        // @include utils(fixed, inset-0);
        // background-color: color('black');
        // overflow-y: scroll;
        // scroll-behavior: smooth;
        // z-index: 100;
    }

    @include breakpoint-up(md) {
        @include utils(flex, flex-jc-center, flex-ai-center);
    }
}

.filter-group--mobile-controls { // <div>
    @include utils(flex, flex-wrap);

    &.--filters {
        @include spacing(mt-4);
    }

    &.--calendar {
        @include spacing(mt-2);
    }

    .link--stubby {
        @include spacing(pl-0);
    }

    @include breakpoint-up(md) {
        @include utils(hidden);
    }

    // Don't judge me..
    // &.--center-on-mobile,
    // .--center-on-mobile & {
        @include utils(text-center);
    // }
}


    .filter-group__heading { // <h6>
        @include utils(relative);
        @include render-font-style(h6-small);
        @include spacing(pr-4);
        color: color('grey');
        top: -3px; // To align with a.link--stubby (with big underlines)

        @include breakpoint-down('sm+') {
            @include utils(hidden);
        }
    }

    .filter-group__close {
        @include utils(absolute, inline-block);
        @include spacing(p4);
        color: color('white');
        top: 1rem;
        right: 1rem;
        cursor: pointer;

        @include breakpoint-up(md) {
            @include utils(hidden);
        }
    }

    .filters-list { // <ul>
        @include breakpoint-up(md) {
            @include utils(flex);
        }
    }

        .filter-item { // <li>
            @include breakpoint-down('sm+') {
                & + & {
                    @include spacing(mt-8);
                }
            }

            & > a {

                .icon {
                    @include utils(inline-block);
                    color: color('black');
                }

                @include breakpoint-down('sm+') {
                    &,
                    &:hover {
                        color: color('grey');
                        margin-left: rem(-6px); // Accounts for box style used on :hover
                    }

                    span {
                        @include utils(border-none);
                    }

                    .icon {
                        @include utils(hidden);
                    }
                }

                &.--collapse-opened, &.collapse-control--open { // <a>
                    background-color: color('black');
                    color: color('white');

                    .icon {
                        transform: rotate(180deg);
                        color: color('white');
                    }
                }
            }
        }

            .filter__sub-nav { // <ul>
                min-width: rem(220px);

                @include breakpoint-down('sm+') {
                    a {
                        @include spacing(py-2, px-0);
                        margin-left: rem(-6px); // Accounts for box style used on :hover

                        &.--abled:hover,
                        &.--abled:active, {
                            @include utils(no-underline);

                            span {
                                border-color: color('black');
                            }
                        }
                    }

                    &.collapse {
                        @include utils(block);
                    }
                }

                @include breakpoint-up('md') { // * //
                    @include spacing(px-2, py-4);
                    @include utils(absolute);
                    background-color: color('black');
                    left: 0;
                    margin-top: rem(-10px); // TODO: Magic number, height of padding + border on the link that opens this nav
                    z-index: 100;

                    a {
                        color: color('white');
                    }
                }

                .filter-item:last-of-type() & {
                    @include breakpoint-up('md') {
                        left: auto;
                        right: 0;
                        min-width: rem(180px);
                    }
                }

                a {
                    @include utils(nowrap, block, font-medium);
                    padding-bottom: rem(2px);
                    // color: color('white'); // * //
                    line-height: 1.5;

                    span {
                        @include utils(inline-block, border2);
                        // @include spacing(px-2,py-1);
                        padding: rem(1px) rem(8px) rem(0) rem(6px);
                        border-color: transparent;
                    }

                    &.--disabled {
                        @include utils(opacity-60, cursor-not-allowed);

                        span {
                            @include utils(line-through);
                        }
                    }

                    &.--abled:hover,
                    &.--abled:active, {
                        @include utils(no-underline);

                        span {
                            border-color: color('white');
                        }
                    }
                }

                .filter-wrapper--subset & {
                    @include breakpoint-up('md') {
                        left: auto;
                        right: 0;
                    }
                }
            }


            .filter-subset-header {
                font-size: rem(32px);
                @include utils(text-center);
                // .--center-on-mobile & {
                //     @include breakpoint-down('sm+') {

                //     }
                // }
            }



// General styling for different block styles (eg, default or subset)

.event-block {
    @include breakpoint-up('md+') {
        @include spacing(mt-10);
    }

    @include breakpoint-up('lg') {
        @include spacing(mt-16);
    }
}

.filter-wrapper--default {
    .filter-block {
        @include spacing(mt-8);

        @include breakpoint-up('md') {
            @include spacing(mt-12)
        }
    }

    .filter-group {
        @include breakpoint-up('md') {
            @include spacing(mt-8);
        }

        @include breakpoint-up('lg') {
            @include spacing(mt-12);
        }
    }

    .filter-heading {
        @include utils(text-center);

        @include breakpoint-down(md) {
            .--faux {
                @include spacing(pl-0);
            }
        }


    }

    .filter-back-link {
        @include utils(hidden);
    }
}

.filter-wrapper--subset {

    .filter-heading {
        @include utils(text-center);
        @include render-font-style(h3);

        @include breakpoint-up('md') {
            @include utils(text-left);
        }

        .--faux {
            @include breakpoint-up('md') {
                @include spacing(ml-n4);
            }
        }
    }

    .filter-block {
        @include breakpoint-up(md) {
            @include utils(flex, flex-jc-between, flex-wrap);
        }
    }
}





