.clearfix {
    @include clearfix;
}

.stretched-link {
    &::after {
        @include abs(0, 0, 0, 0, 1);
        content: '';
        pointer-events: auto;
        background-color: transparent;
    }
}

.appearance-none {
    appearance: none !important;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}

.contains-icon {
    display: inline-flex;
    align-items: center;
}

.invisible-scrollbars {
    @include invisible-scrollbars;
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}