// set default border rules for all elements
// this allows us to specify only e.g `.border` or `.border-top` to an element
// and sensible defaults are applied
* {
    border-width: 0;
    border-style: solid;
    border-color: $border-color;
}

@include make-responsive-util-classes($borders);
