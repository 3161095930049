body,
html {
    min-height: 100%;
}

::selection {
    background-color: color('mint');
    background-color: var(--theme-color);
    color: color('black');
}

::-moz-selection {
    background-color: color('mint');
    background-color: var(--theme-color);
    color: color('black');
}
