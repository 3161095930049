/*

Typical HTML
------------

<div class="container">
    <ul class="grid grid--gutters">
        {% for page in list %}

            <li class="mb-8 md:c6 md:mb-0 md+:c4">
                <div class="card">
                    <div class="card__body">
                        <h4>
                            <a class="stretched-link" href="{{ page.url }}">{{ page.title }}</a>
                        </h4>
                        <p>{{ page.text }}</p>
                    </div>
                    <div class="card__image">
                        {% image page.get_listing_image max-720x600 as page_image %}
                        <img src="{{ page_image.url }}" />
                    </div>
                </div>
            </li>

        {% endfor %}
    </ul>
</div>


Some accessibility things are in play here, namely:

- using a list for the overall structure,
- placing the text (specifically the heading) first, then the image and re-ordering using flex-order

 */


.card {
    @include utils(h-100, flex, flex-wrap, relative);

    * {
        @include transition(transform margin padding, 0.1s, 'out');
    }

    .card__image {
        @include utils(flex-order-1, flex-fill);
        height: size('56');

        img {
            @include utils(w-100, h-100);
            object-fit: cover;
        }
    }

    .card__body {
        @include utils(flex-order-2, flex-fill);
        @include spacing(pt-6);
    }

        h4 {
            @include utils(font-bold);
            @include spacing(mb-2);
        }

        a {
            @include utils(no-underline);
            color: color('black');
            border-bottom: rem(4px) solid transparent;
        }


    @include breakpoint-only('sm+') {
        @include utils(inline-flex);

        .card__image {
            @include utils(w-5/12, border-none, h-100);
            @include spacing(pr-6);
        }

        .card__body {
            @include utils(w-7/12);
        }
    }


    &:hover {
        @include utils(cursor-pointer);

        * {
            @include transition(transform margin padding, 0.08s, 'in');
        }

        .card__body {
            // Using transform or position to move this element affects the .streched-link
            // This looks gross, but works. TODO: More testing required..
            margin-top: -0.25em;
            padding-bottom: 0.25em;
        }

        .card__image {
            transform: scale(1.04);
        }

        a {
            @include utils(no-underline);
            border-color: color('black');
        }
    }

}
