/*
* Config Functions.
* These functions are required for generating config maps
*/

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function escape-class($classname) {
    $classname: str-replace($classname, ':', '\\:');
    $classname: str-replace($classname, '/', '\\/');
    $classname: str-replace($classname, '+', '\\+');
    @return $classname;
}

// mapValuesToRule - this is useful when you want to use make-responsive-util-classes
// but there is only one property you want to set on a class - it is nicer just to specify
// a single value.
// $set - map - a map of classNames to values
// $rule - css property - the rule values should be applied to e.g. width
@function map-values-to-rule($set, $rule) {
    $next-set: ();
    @each $className, $val in $set {
        $next-set: map-merge($next-set, ($className: ($rule: $val)));
    }
    @return $next-set;
}

// combines multiple maps since sass map-merge can only merge 2 maps
@function merge-maps($maps...) {
    $all-maps: ();
    @each $map in $maps {
        $all-maps: map-merge($all-maps, $map);
    }
    @return $all-maps;
}

@function map-get-strict($map, $key) {
    @if map-has-key($map, $key) {
        @return map-get($map, $key);
    } @else {
        @error 'ERROR: Specified key [#{$key}] does not exist in the mapping';
    }
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

// assumes a base font size of 16px by default.
// in reality we use a base font of 1rem so as not to disable user font settings.
@function rem($px, $base: 16) {
    $val: strip-unit($px) / $base;
    @return $val * 1rem;
}

