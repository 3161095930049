.bt-white-muted {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bt-grey-30 {
    border-top: 1px solid color(grey-30);
}

.bb-grey-30 {
    border-bottom: 1px solid color(grey-30);
}

.by-grey-30 {
    border-bottom: 1px solid color(grey-30);
    border-top: 1px solid color(grey-30);
}

.global-nav {
    padding-left: 120px;
}

.mas-logo {
    position: absolute;
    width: 87px;
    height: 84px;
    margin-right: 20px;
    z-index: 555;
    top: -2px;
}

.global-header {
    border-bottom: 1px solid #d8dae1;
    padding-top: 22px;
    padding-bottom: 22px;
    position: relative;
    z-index: 999;
}

.global-nav__dropdown {
    background: #fbfcfd;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .1);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all .5s ease;
    left: -22px;
    display: none;
    padding: 15px 25px 15px 20px !important;
    border-radius: 5px;
    font-size: 15px;
    z-index: 99;
    min-width: 170px;
}
