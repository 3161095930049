.side-menu {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: scroll;

    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
}
