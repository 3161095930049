@mixin form-controls() {
    select,
    textarea,
    input[type=text],
    input[type=password],
    input[type=number],
    input[type=email],
    input[type=url],
    input[type=search],
    input[type=tel],
    .form-control {
        @content;
    }
}

@include form-controls() {
    width: 100%;
    // height: calc(1.5em + 0.75rem + 2px);
    padding: 0.75rem 1rem;
    background-color: color('grey-5');
    background-clip: padding-box;
    border-bottom: 1px solid $border-color;
    // border-radius: 4px;

    &::placeholder {
        color: color('grey-40');
        font-weight: $font-weight-light;
    }

    &:disabled,
    &[readonly] {
        background-color: color('grey-30');
    }
}


.bg-grey-5,
.bg-grey-10 {
    @include form-controls() {
        background-color: color('white');
    }
}

label {
    @include render-font-style(h5);
    @include spacing(mb-2);
}


input[type=file],
input[type=range],
.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}


select,
select.form-control {
    &[size],
    &[multiple] {
        height: auto;
    }
}

textarea,
textarea.form-control {
    height: auto;
}


.default-margins {
    .form-group {
        @include spacing(mt-6);

        + * {
            @include spacing(mt-6);
        }
    }

    label + {
        @include form-controls() {
            @include spacing(mt-1); }
    }
}

.form--fieldset-mb0 {
    fieldset {
        margin-bottom: rem(2px) !important;
    }
}


// Supposedly cross-browser accessible styling..
// Source: https://scottaohara.github.io/a11y_styled_form_controls/src/radio-button/

.radio {
    @include utils(relative);

    & > input[type="radio"] {
        appearance: none;
        background: transparent;
        opacity: .00001;
        z-index: 2;
    }

    & > label {
        @include utils(inline-block);
        padding: .75em .5em .75em 2em;
    }

    & > input[type="radio"],
    & > label:before,
    & > label:after {
        border: 2px solid;
        height: 1.125em;
        left: .125em;
        position: absolute;
        top: .825em;
        width: 1.125em;
        border-radius: 100%;
        content: " ";
        transition:
            border-color .2s ease-in-out,
            box-shadow .2s ease-in-out;
    }

    & > label:after {
        border-color: #565656;
    }

    & > label:before {
        border-color: transparent;
        background: #FFFFFF;
        box-shadow: 0 0 0 0px rgba(0,0,0,0);
    }

    & > input:checked ~ label:before {
        border-color: transparent;
        box-shadow: 0 0 0 2px #0d5192;

    }

    & > input:focus ~ label:before {
        border-color: transparent;
        box-shadow: 0 0 0 4px #228BEC;
    }

    & > input:checked ~ label:after {
        border-color: #fff;
        border-width: 4px;
        box-shadow: inset 0 0 0 8px #0d5192;
    }

    .culture-club & {
        & > input:checked ~ label:before {
            box-shadow: 0 0 0 2px var(--theme-color);
        }

        & > input:checked ~ label:after {
            box-shadow: inset 0 0 0 8px var(--theme-color);
        }
    }
}

    .radio-lockup {
        @include spacing(mt-n8, ml-n8);

        .radio {
            @include spacing(mt-8, ml-8)
        }
    }


.checkbox {
    @include utils(relative, flex, flex-nowrap, flex-ai-center);

    input {
        @include utils(flex-shrink-0);
        -webkit-appearance: none;
        background: #FFFFFF;
        border-radius: 4px;
        border: 2px solid color('grey');
        font-size: inherit;
        height: 2em;
        line-height: 1;
        margin: 0;
        position: relative;
        vertical-align: bottom;
        width: 2em;

        &:before,
        &:after {
          content: "";
          position: absolute;
          transition: transform .2s ease-in-out;
          border-bottom: 3px solid #0d5192;
          border-right: 3px solid #0d5192;
          bottom: 0;
          height: 1em;
          margin: auto;
          right: .5em;
          top: -.5em;
          transform: rotate(45deg) scale(0);
          width: .625em;
        }

        &:checked {
            border-color: #0d5192;
            box-shadow: inset 0 0 20px 3px rgba(34, 139, 236, .05);
        }

        &:checked:before {
            transform: rotate(45deg) scale(1);
        }

        &:focus {
            box-shadow: 0 0 0 1px #0d5192;
            border: 2px solid #0d5192;
            outline: 1px dotted #0d5192;
            outline-offset: 3px;
        }
    }

    label {
        position: relative;
        left: -.25em;
        padding-left: .75em;
        margin-bottom: 0;
    }

    .culture-club & {
        input {
            &:before,
            &:after {
                border-bottom: 3px solid var(--theme-color);
                border-right: 3px solid var(--theme-color);
            }

            &:checked {
                border-color: var(--theme-color);
            }

            &:focus {
                box-shadow: 0 0 0 1px var(--theme-color);
                border: 2px solid var(--theme-color);
                outline: 1px dotted var(--theme-color);
            }
        }
    }

    & + & {
        @include spacing(mt-4);
    }
}






