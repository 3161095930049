/**
 * GitHub Gist Theme
 * Author : Louis Barranqueiro - https://github.com/LouisBarranqueiro
 */

[class*='language-'],
.hljs {
    display: block;
    background: #fdfdfd;
    padding: 30px;
    color: #333333;
    overflow-x: auto;
    border-left: 2px solid #f5f5f5;
    border-right: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
    border-top: 2px solid #f5f5f5;
    margin-top: 1rem;
}

.hljs-comment,
.hljs-meta {
    color: #969896;
}

.hljs-string,
.hljs-variable,
.hljs-template-variable,
.hljs-strong,
.hljs-emphasis,
.hljs-quote {
    color: #df5000;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-type {
    color: #a71d5d;
}

.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-attribute {
    color: #0086b3;
}

.hljs-section,
.hljs-name {
    color: #63a35c;
}

.hljs-tag {
    color: #333333;
}

.hljs-title,
.hljs-attr,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
    color: #795da3;
}

.hljs-addition {
    color: #55a532;
    background-color: #eaffea;
}

.hljs-deletion {
    color: #bd2c00;
    background-color: #ffecec;
}

.hljs-link {
    text-decoration: underline;
}
