
.stacked-menu {
    @include utils(list-none, font-medium);
    @include spacing(p0);

    li {
        @include spacing(py-3);
        border-bottom: 1px solid color(grey-30);

        &:last-child {
            border-bottom: 0 none;
        }
    }
}
