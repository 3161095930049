
.grid {
    @include grid;
}

.grid--gutters {
    @include gutters(16px);

    @include breakpoint-up('md') {
        @include gutters(32px);
    }
}

.grid--gutters-sm {
    @include gutters(12px);
}

@include make-responsive-util-classes($grid-column-classes, $important: false);

body .grid--debug {
    padding: 4px !important;
    border: 1px dashed color('red');

    > * {
        border: 1px dashed color('dark-green');
    }
}
