$var: 1fr;

@for $i from 1 through 32 {
    .grid-columns--#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr);
        -ms-grid-columns: $var;
    }

    $var: append($var, 1fr);
}

@for $j from 1 through 32 {
    .grid-span--#{$j} {
        grid-column-end: span #{$j};
        -ms-grid-column-span: #{$j};

        + [class*="grid-span--"] {
            -ms-grid-column: #{$j+1};
        }
    }
}
