.debug * {
    background: rgba(#F9CA24, 0.05);
    box-shadow: 0 0 0 1px #F9CA24;

    &::after,
    &::before {
        background: rgba(#FF7979, 0.05);
        box-shadow: 0 0 0 1px #FF7979;
    }
}

body.develop {
    &::before {
        content: '';
        display: inline-block;
        padding: 2px 8px;
        font-size: 0.75rem;
        font-weight: 500;
        color: color('white');
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1000;

        @each $bp, $_ in $breakpoints {
            @include breakpoint-up($bp) {
                content: '#{$bp}';
                background: #f368e0;
            }
        }

        @include print {
            display: none !important;
        }
    }

}

body.develop.iframe-container::before {
    background: color('red');
    bottom: 50px;
}
