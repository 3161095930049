@mixin default-margins() {
    .default-margins & {
        @content;
    }
}

@mixin clearfix () {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin abs($t:auto, $r:auto, $b: auto, $l: auto, $z:auto) {
    position: absolute;
    top: $t;
    right: $r;
    bottom: $b;
    left: $l;
    z-index: $z;
}

@mixin fixed($t:auto, $r:auto, $b: auto, $l: auto, $z:auto) {
    position: fixed;
    top: $t;
    right: $r;
    bottom: $b;
    left: $l;
    z-index: $z;
}


@mixin list-reset {
    @include spacing(m0, p0);
    @include utils(list-none);
}

// hides scrollbars in supported browsers while maintaining scroll capability
@mixin invisible-scrollbars {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin nice-scrollbars {
    // webkit only progressive enhancement
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: color('grey-30');
    }

    &::-webkit-scrollbar-thumb {
        background-color: color('bright-blue');
    }
}


// https://material.io/design/motion/speed.html#easing
$easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
$easing-in: cubic-bezier(0, 0, 0.2, 1);
$easing-out: cubic-bezier(0.4, 0, 1, 1);
$easings: (
    standard: $easing-standard,
    in: $easing-in,
    out: $easing-out,
);

@mixin transition($props, $duration: 0.3s, $ease: standard) {
    $result: ();
    $easing: map-get-strict($easings, $ease);

    @for $i from 1 through length($props) {
        $prop: nth($props, $i);
        $result: append($result, $prop);
        $result: append($result, $duration);
        $result: append($result, $easing);
        @if $i != length($props) {
            $result: append($result, unquote($string: ','));
        }
    }

    transition: $result;
}

@mixin focusable {
    a[href],
    area[href],
    input:not([disabled]),
    select:not([disabled]),
    textarea:not([disabled]),
    button:not([disabled]),
    iframe,
    [tabindex],
    [contentEditable=true] {
        &:not([tabindex='-1']) {
            @content;
        }
    }
}

@mixin print {
    @media print {
        @content;
    }
}

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

// CSS triangles
@mixin triangle($direction:'up', $size:'8px', $color:'#000000'){
    width: 0;
    height: 0;
    border: 0 solid transparent;

    @if ($direction = 'up') {
        border-right-width: #{$size};
        border-left-width: #{$size};
        border-bottom: #{$size} solid #{$color};
    }
}
